<template lang='pug'>
pools-info(v-if="poolsInfo", :info="poolsInfo")
pagination(v-if="pages > 1", :current-page="page", :pages="pages")
</template>

<script>
import PoolsInfo from '../components/tables/PoolsInfo'
import Pagination from '../components/Pagination'
import { getPools } from '../assets/scripts/API'
import { useI18n } from 'vue-i18n'
import { addMeta } from '../assets/scripts/add-meta'

export default {
  name: 'PoolsPage',
  components: { Pagination, PoolsInfo },
  data () {
    return {
      page: 1,
      poolsInfo: null,
      pages: 1
    }
  },
  methods: {
    async getDataPage (page) {
      const data = await getPools(page)
      this.poolsInfo = data
      this.pages = data.pages
      this.page = data.page
    }
  },
  mounted () {
    this.getDataPage(this.page)
    addMeta(this.t('title'), this.t('description'))
  },
  beforeRouteUpdate (to, from, next) {
    this.page = +to.query.page
    this.getDataPage(this.page)
    next()
  },
  watch: {
    '$i18n.locale' () {
      addMeta(this.t('title'), this.t('description'))
    }
  },
  setup () {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local',
      messages: {
        ru: {
          title: 'Пулы для майнинга криптовалют',
          description: 'Полный список майнинг пулов, доступных на портале cryptopack.ru Возможность просмотра количества добываемых монет, комиссий, минимальных выплат'
        },
        en: {
          title: 'Cryptocurrency mining pools',
          description: 'A complete list of mining pools available on the cryptopack.ru portal Ability to view the number of mined coins, commissions, minimum payouts'
        },
        es: {
          title: 'Grupos de minería de criptomonedas',
          description: 'Una lista completa de grupos de minería disponibles en el portal cryptopack.ru Capacidad para ver la cantidad de monedas extraídas, comisiones, pagos mínimos'
        },
        pt: {
          title: 'Pools de mineração de criptomoedas',
          description: 'Uma lista completa de pools de mineração disponíveis no portal cryptopack.ru Capacidade de visualizar o número de moedas extraídas, comissões, pagamentos mínimos'
        },
        de: {
          title: 'Mining-Pools für Kryptowährungen',
          description: 'Eine vollständige Liste der Mining-Pools, die auf dem Portal cryptopack.ru verfügbar sind. Möglichkeit, die Anzahl der abgebauten Münzen, Provisionen und Mindestauszahlungen anzuzeigen'
        },
        fr: {
          title: 'Pools de minage de crypto-monnaie',
          description: 'Une liste complète des pools de minage disponibles sur le portail cryptopack.ru Possibilité d\'afficher le nombre de pièces minées, les commissions, les paiements minimums'
        },
        it: {
          title: 'Pool di mining di criptovaluta',
          description: 'Un elenco completo dei pool di mining disponibili sul portale cryptopack.ru Possibilità di visualizzare il numero di monete estratte, commissioni, pagamenti minimi'
        },
        pl: {
          title: 'Pule do wydobywania kryptowalut',
          description: 'Pełna lista puli wydobywczych dostępna na portalu cryptopack.ru Możliwość podglądu liczby wydobytych monet, prowizji, minimalnych wypłat'
        },
        zh: {
          title: '加密貨幣礦池',
          description: 'cryptopack.ru 門戶上可用的礦池完整列表 能夠查看開采的硬幣數量、佣金、最低支出'
        },
        ja: {
          title: '暗号通貨マイニングプール',
          description: 'cryptopack.ruポータルで利用可能なマイニングプールの完全なリストマイニングされたコインの数、コミッション、最小支払いを表示する機能'
        }
      }
    })
    return { t }
  }
}
</script>
